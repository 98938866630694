@import "../../helpers/variable.scss";

.footer_container {
    background: #534c5c;
    color: rgb(255, 255, 255);
    // height: $footerHeight;
    align-items: center;
    display: flex;
    justify-content: center;
    // position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 3;
    flex-wrap: wrap;
}
.img{
    height: 240px;
    margin-right: 20px;
    img{
        height: 100%;
    }
}
.version {
    position: absolute;
    bottom: 0;
    left: 0;
    color: white;
}
.block {
    display: flex;
    align-items: center;
}
.form {
    background: white;
    margin: 20px;
    border-radius: 20px;
}
.fast_top {
    width: 44px;
    height: 44px;
    margin-left: 20px;
    border: 1px solid;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.6s ease;
    &:hover{
        color: #65666C;
        border-color: #9e9e9e;
        background: #9e9e9e;
    }
}
.wrapper{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
@media screen and (max-width: 400px) {
    .img{
        margin-right: 0;
    }
}
@media screen and (max-width: 330px) {
    .footer_container {
        font-size: 14px;
    }
}
