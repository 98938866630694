.wrapper_pre_header {
    background-color: #161940;
    height: 45px;
    z-index: 1111111111111;
    width: 100%;
    display: flex;
    align-items: center;
    flex-flow: wrap;
    a {
        color: #0095eb;
        display: flex;
        margin-left: 15px;
        text-decoration: none;
        svg {
            margin-right: 7px;
            color: #bbb;
        }
    }
}
.text {
    margin-left: 200px;
    color: #bbb;
}
.displayNone{
    display: none;
}

@media screen and (max-width: 370px) {
    .text {
        margin-left: 33vw;
    }
    .wrapper_pre_header {
        a{
            margin-left: 6px;
            font-size: 3.6vw;
        }
    }
}
