@import "../../helpers/variable.scss";

.contacts_content_wrapper {
    display: flex;
    margin-bottom: 20px;
    * {
        font-family: "default_header";
    }
}
h1 {
    font-family: "default_header";
    text-transform: uppercase;
    font-weight: 100;
}
.contacts_img_block {
    width: 100%;
    img {
        width: 100%;
    }
}
.contacts_information_block {
    width: 50%;
    margin-right: 10px;
}
.contacts_current_item {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    h2{
        color: #000;
    }
}
.contacts_block_call {
    min-width: 140px;
    a {
        display: flex;
        justify-content: space-between;
        text-decoration: none;
        color: #000;
        transition: all 0.5s;
        img {
            width: auto;
            height: 100%;
        }
        &:hover {
            color: red;
        }
    }
}
.contacts_block_call_mini {
    min-width: 70px;
    a {
        display: flex;
        justify-content: space-between;
        text-decoration: none;
        color: #000;
        transition: all 0.5s;
        img {
            width: auto;
            height: 100%;
        }
        &:hover {
            color: red;
        }
    }
}

@media screen and (max-width: 850px) {
    .contacts_content_wrapper {
        flex-direction: column;
    }
    .contacts_information_block {
        margin-bottom: 20px;
        width: 100%;
        margin-right: 0;
    }
    .contacts_content_wrapper {
        text-align: initial;
    }
}
