@import "../../helpers/variable.scss";

.header_container {
    z-index: 120;
    background: #f9f9f9;
    height: $headerHeight;
    width: 100%;
    position: sticky;
    left: 0;
    top: 0;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.07);
}
.header_logo_container {
    max-height: 100%;
    max-width: none;
    align-self: center;
    img {
        height: 60px;
        width: auto;
    }
}
.container {
    display: flex !important;
    justify-content: space-between;
    height: 100%;
}
.header_tabs_container {
    display: flex;
    position: relative;
}
.header_tab {
    cursor: pointer;
    .header_tab_all {
        text-align: left;
        align-items: center;
        display: flex;
        text-decoration: none;
        color: #6d6d6d;
        height: 100%;
        font-size: 14px;
        text-transform: uppercase;
        transition-property: color;
        transition: color 0.5s;
        position: relative;
        &:hover {
            color: #000;
        }
    }
    .header_tab_all_last {
        text-align: center;
        align-items: center;
        display: flex;
        text-decoration: none;
        height: 100%;
        font-size: 14px;
        text-transform: uppercase;
        transition-property: color;
        transition: color 0.5s;
        position: relative;
        color: #bf0202;
        &:hover {
            color: #910303;
        }
    }
    &:first-child {
        margin-left: 0 !important;
    }
    &:nth-child(n-1) {
        margin-left: 20px;
    }
}
.header_button_burger {
    display: none;
    justify-content: center;
    margin: 20px;
}
.header_tab_active {
    &::before {
        content: "";
        z-index: 1;
        position: absolute;
        width: 100%;
        top: 0;
        border-top: 3px solid #006199 !important;
    }
    color: #000 !important;
    box-sizing: border-box;
}
.header_line {
    height: 3px;
    //   background: #161940;
    background: #0095eb;
    position: absolute;
    top: 0;
    transition: left 0.4s, width 0.4s;
}

.menu_wrapper {
    outline: 0;
    outline-offset: 0;
    margin-top: 12px;
    cursor: pointer;
}
.menu_conatiner_wrapper {
    display: none;
}
@media screen and (max-width: 907px) {
    .menu_conatiner_wrapper {
        display: block;
    }
    .header_line {
        display: none;
    }
    .header_button_burger {
        display: flex;
    }
    .header_tab_active {
        &::before {
            display: none;
        }
    }
    .header_tabs_container {
        display: none !important;
    }
    [class*="bm-overlay"] {
        top: 0px;
        z-index: 100 !important;
    }
    [class*="bm-menu-wrap"] {
        top: 80px;
        overflow: hidden;
        outline: none;
        background: #f9f9f9;
        padding-left: 20px;
        z-index: 101 !important;
        [class*="bm-cross-button"] {
            display: none;
        }
        * {
            outline: none;
        }
    }
    .header_tab {
        &:nth-child(n) {
            margin-left: 0;
            padding-top: 18px;
        }
        &:nth-child(6) {
            margin-bottom: 20px;
        }
    }
    .header_display_none {
        display: none;
    }
}
@media screen and (max-width: 500px) {
    [class*="bm-menu-wrap"] {
        width: 60% !important;
    }
}
@media screen and (max-width: 390px) {
    .header_logo_container {
        img {
            height: auto;
            width: 230px;
        }
    }
}
@media screen and (max-width: 1205px) and (min-width: 908px) {
    .header_tab {
        cursor: pointer;
        .header_tab_all {
            font-size: 1vw;
        }
        &:nth-child(n-1) {
            margin-left: 10px;
        }
    }
}
