@import "./helpers/variable.scss";

.App {
  text-align: center;
  min-height: 100vh;
  position: relative;
  background: radial-gradient(#eaeaea, transparent);
}
.App_overflow {
  overflow: visible;
}
.app_mobile_wrapper {
  position: absolute;
  z-index: 111;
}
.App_logo {
  height: 40vmin;
  pointer-events: none;
}

.home_skeleton {
  margin-top: 20px;
  height: 400px !important;
}
.home_transitions {
  transform: none !important;
}
body{
  overflow-x: initial !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App_logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App_header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.version {
  position: absolute;
  bottom: 0;
  left: 0;
}
.App_link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
* {
  font-family: "default";
  // font-weight: 500;
  // text-transform: uppercase;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
