.info_container_wrapper {
    padding: 10px 0;
    // margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: space-between;
    // flex-wrap: wrap;
    // position: relative;
    // flex-direction: row-reverse;
    h2 {
        color: #161940;
        /* text-shadow: 0 0 black; */
        font-size: 38px;
        line-height: 38px;
        font-weight: 400;
        letter-spacing: 1px;
        margin-bottom: 15px;
    }
}
.back{
    display: flex;
    justify-content: center;
    a{
        text-decoration: none;
        color: #0095eb;
        transition: all 0.5s;
        &:hover{
            color:#c40e0e;
        }
    }
}
.wrapper_info_block {
    display: flex;
    flex-direction: column;
    max-width: 700px;
}
.block_double {
    display: flex;
}
.cur_block {
    display: flex;
    flex: 1;
    svg {
        width: 50px;
        height: 50px;
        color: #c40e0e;
        margin-top: 30px;
    }
}
.text_wrapper {
    // margin-left: 30px;
    // width: 138px;
    h4{
        span{
            margin-left: 7px;
        }
    }
    h4 {
        text-align: left;
        margin-bottom: 7px;
        font-size: 17px;
        line-height: 20px;
        color: #161940;
        font-weight: 400;
    }
    span,
    li,
    div {
        text-align: left;
        color: #656565;
        font-size: 13px;
        line-height: 19px;
        font-weight: 400;
        letter-spacing: 0;
        font-family: "Lato", Arial, Tahoma, sans-serif;
        margin-bottom: 10px;
    }
}
.top {
    margin-top: 20px;
}
