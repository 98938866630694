.info_container_wrapper {
    padding: 10px 0;
    // margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: space-between;
    // flex-wrap: wrap;
    // position: relative;
    // flex-direction: row-reverse;
    h2{
        color: #161940;
    /* text-shadow: 0 0 black; */
    font-size: 38px;
    line-height: 38px;
    font-weight: 400;
    letter-spacing: 1px;
    margin-bottom: 15px;
    }
}
.wrapper_info_block{
    display: flex;
    flex-direction: column;
    max-width: 550px;
}
.block_double{
    display: flex;
    flex-flow: wrap;
}
.cur_block{
    display: flex;
    flex: 1;
    justify-content: center;
    svg{
        width: 50px;
        height: 50px;
        color: #c40e0e;
        margin-top: 30px;
    }
}
.text_wrapper{
    margin-left: 30px;
    width: 138px;
    h4{
        text-align: left;
        margin-bottom: 7px;
        font-size: 17px;
        line-height: 20px;
        color: #161940;
        font-weight: 400;
    }
    div{
        text-align: left;
        color: #656565;
        font-size: 13px;
        line-height: 19px;
        font-weight: 400;
        letter-spacing: 0;
        font-family: "Lato",Arial,Tahoma,sans-serif;
    }
}
.top{
    margin-top: 20px;
}