.info_container_wrapper {
    padding: 10px 0;
    background: white;
    // margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: space-between;
    // flex-wrap: wrap;
    // position: relative;
    // flex-direction: row-reverse;
    h2 {
        color: #161940;
        /* text-shadow: 0 0 black; */
        font-size: 38px;
        line-height: 38px;
        font-weight: 400;
        letter-spacing: 1px;
        margin-bottom: 15px;
    }
}
.wrapper_info_block {
    display: flex;
    width: 100%;
    justify-content: center;
    img {
        max-width: 1000px;
        width: 100%;
        padding: 10px;
    }
}
.block_double {
    display: flex;
    flex-flow: wrap;
}
.cur_block {
    display: flex;
    flex: 1;
    justify-content: center;
    svg {
        width: 50px;
        height: 50px;
        color: #c40e0e;
        margin-top: 30px;
    }
}
.text_wrapper {
    margin-left: 30px;
    width: 138px;
    h4 {
        text-align: left;
        margin-bottom: 7px;
        font-size: 17px;
        line-height: 20px;
        color: #161940;
        font-weight: 400;
    }
    div {
        text-align: left;
        color: #656565;
        font-size: 13px;
        line-height: 19px;
        font-weight: 400;
        letter-spacing: 0;
        font-family: "Lato", Arial, Tahoma, sans-serif;
    }
}
.text_wrapper_2 {
    margin-top: 20px;
    text-align: left;
    color: #656565;
    font-size: 13px;
    line-height: 19px;
    font-weight: 400;
    letter-spacing: 0;
    margin-left: 10px;
    font-family: "Lato", Arial, Tahoma, sans-serif;
}
.top {
    margin-top: 20px;
}
.img_block {
    height: 200px;
    margin: auto;
    position: relative;
    img {
        height: 100%;
        position: absolute;
        top: -13px;
    }
}
@media screen and (max-width: 500px) {
    .img_block {
        img {
            top: 0;
            right: -38vw;
        }
    }
}
