.contacts_img_block{
    width: 100%;
    position: relative;
    img{
        width: 100%;
        height: 425px;
        object-fit: cover;
        object-position: 58% 50%;
    }
}

@media screen and (max-width: 620px) {
    .contacts_img_block{
        img{
            height: 64vw;
        }
    }
}
