.information_block_card {
    align-items: center;
    text-align: start;
    padding: 0 24px 0 35px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    h3{
        color: #171c5d;
    margin-bottom: 55px;
    margin-top: 50px;
    font-size: 26px;
    line-height: 26px;
    }
    background-image: url('../../img/note.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.information_container {
    margin: 20px 0;
}
.wrapper_block{
    display: flex;
}
.cur_block{
    width: 140px;
    margin: 0 50px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    img{
        position: relative;
        width: fit-content;
        top:0;
        transition: top 0.4s;
    }
    h4{
        font-size: 18px;
    line-height: 20px;
    color: #282d75;
    font-weight: 400;
    letter-spacing: 1px;
    font-family: "Libre Baskerville",Arial,Tahoma,sans-serif;
}
.line{
    height: 2px;
    background: #c40e0e;
    width: 0px;
    margin-top: 12px;
    transition: width 0.4s;
}
&:hover{
   img{
    top:-5px;
   }  
   .line{
       width: 100px;
   }   
}
}