.page404_container{
    align-items: center;
    justify-content: center;
    height: calc(100vh - 234px);
    display: flex;
    flex-direction: column;
    min-height: 100px;
}
.page404_code_and_message_wrapper{
    display: flex;
    justify-content: center;
}
.page404_code{
    border-right: 2px solid;
    font-size: 26px;
    padding: 0 15px 0 15px;
    text-align: center;
}
.page404_message{
    font-size: 18px;
    text-align: center;
    padding: 10px;
}
.page404_link{
    a{
        text-decoration: none;
        color: #045dff;
    }
}