
$headerHeight: 80px;
$footerHeight:60px;

@mixin indentationHeader {
    // padding-top: $headerHeight;
    padding-bottom: $footerHeight;
  }
  @mixin cursorPointer {
    cursor: pointer;
  }
  @mixin colorPicker {
    .table_color_def { 
        @include cursorPointer;
        &:hover{
          background: rgb(247, 247, 247);
        }
    }
  .table_color_new {
      background: #ffc0c044; 
      &:hover{
        background: #ffa8a8;
      }
      @include cursorPointer;
  }
  .table_color_warn { 
      background: #ffe5853b; 
      &:hover{
        background: #ffe58562;
      }
      @include cursorPointer;
  }
  .table_color_off { 
      background: #eaeaea; 
      &:hover{
        background: #dadada;
      }
      @include cursorPointer;
  }
  .table_color_pre { 
      background: #e8ffe29a; 
      &:hover{
        background: #c7ffb99a;
      }
      @include cursorPointer;
  }
  }

  @font-face {
    font-family: 'Open Sans';
    src: url("../font/OpenSans-Bold.ttf");
  }
  @font-face {
    font-family: 'default';
    src: url("../font/OpenSans-SemiBold.ttf");
  }
  @font-face {
    font-family: 'default_header';
    src: url("../font/OpenSans-Light.ttf");
  }