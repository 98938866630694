.style_select {
    color: white !important;
    margin-left: 20px;

    img {
        display: flex;
        width: 100%;
        max-width: 30px;
    }
    svg {
        color: white;
    }
    fieldset {
        border-color: white !important;
    }
    [class*="MuiSelect-root MuiSelect-select MuiSelect-selectMenu MuiSelect-outlined MuiInputBase-input MuiOutlinedInput-input"] {
        padding: 12.5px 14px;
    }
}
.style_menu {
    img {
        width: 100%;
        max-width: 40px;
    }
}
