.info_container_wrapper {
    background: white;
    // margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: space-between;
    // flex-wrap: wrap;
    // position: relative;
    // flex-direction: row-reverse;
    height: 300px;
    img{
        width: 100%;
        height: 425px;
        object-fit: cover;
    }
}
