.login_block_content{
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 20px 35px;
    img{
        width: 100%;
    }
}
.login_button_submit{
    margin-top: 15px !important;
}
.login_remember_me_container{
    margin-top: 12px;
}
.login_loading{
    color: red !important;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -12px;
    margin-left: -12px;
}
.login_block_card{
    height: fit-content;
    width: 500px;
    background: none !important;
    box-shadow: none !important;
    form{
        width: 80%;
    }
}
.login_button_wraper{
    position: relative;
    margin-top: 13px;
    button{
        width: 100%;
    }
}
.login_container_wrapper{
    height: 100vh;
    align-items: center;
    display: flex !important;
}

h2{
    color: #171c5d;
    margin-bottom: 20px;
    margin-top: 20px;
    font-size: 26px;
    line-height: 26px;
}
@media screen and (max-width: 500px) {
    .login_block_card{
        width: 300px;
    }
}
@media screen and (max-width: 400px) {
    .login_block_card{
    form{
        width: auto;
    }
}
}